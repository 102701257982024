import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"
import './roulette.scss'
import {useState} from "react";

const Wheel = (props) => {

    const data = [
        {option: '1', image: <StaticImage src={"../images/armin.png"} alt={"Armin"}/>},
        {option: '2', image: <StaticImage src={"../images/panther.png"} alt={"Panther"}/>},
        {option: '3', image: <StaticImage src={"../images/ivan.png"} alt={"Ivan"}/>},
        {option: '4', image: <StaticImage src={"../images/raul.png"} alt={"Raul"}/>},
        {option: '5', image: <StaticImage src={"../images/robles.png"} alt={"Robles"}/>},
        {option: '6', image: <StaticImage src={"../images/random.png"} alt={"Random"}/>},
        {option: '7', image: <StaticImage src={"../images/dany.png"} alt={"Dany"}/>},
        {option: '8', image: <StaticImage src={"../images/javier.png"} alt={"Javier"}/>},
        {option: '9', image: <StaticImage src={"../images/matz.png"} alt={"Matzerath"}/>},
        {option: '10', image: <StaticImage src={"../images/diego.png"} alt={"Diego"}/>},
        {option: '11', image: <StaticImage src={"../images/ernst.png"} alt={"Ernst"}/>},
        {option: '12', image: <StaticImage src={"../images/ara.png"} alt={"Ara"}/>},

    ]

    const spins = 2 * 360;
    const time = 5;
    const rouletteSize = props.size;
    const imageDeg = 360 / data.length;
    const imageWidth = (2 * Math.PI * (rouletteSize / 2)) / data.length;
    const imageHeight = rouletteSize / 2;
    const [spinning, setSpinning] = useState(false)
    const [willSpin, setWillSpin] = useState(0)
    const [fixNumber, setFixNumber] = useState(1)
    let result = function (r) {
        props.cb(r)
    }


    function start() {
        if (!spinning) {
            setFixNumber(fixNumber + 1);
            let winnerDeg = getRandomArbitrary(1, data.length)
            let winnerNumber = winnerDeg * imageDeg
            setSpinning(true);
            setWillSpin((fixNumber * spins) + (winnerNumber));
            finish(winnerDeg);
        }
    }

    function finish(win) {
        setTimeout(function () {
            result(win);
            setSpinning(false);
        }, time * 1000)
    }

    function getRandomArbitrary(min, max) {
        return Math.round(Math.random() * (max - min) + min);
    }

    return (<>
        <div className="content-roulette">
            <div className={'roulette'} style={{
                width: rouletteSize,
                height: rouletteSize,
            }}>
                <div className="wrapper" style={{
                    transition: willSpin > 0 ? "all " + time + "s cubic-bezier(0.47, 0.65, 0.9, 1.07)" : "none",
                    transform: 'rotate(' + willSpin + 'deg)'
                }}>
                    {data.map((item, index) => {
                        let deg = (index + 1) * imageDeg;
                        return (
                            <div className={'roulette-item'}
                                 style={{transform: `rotate(${deg}deg)`, width: imageWidth, height: imageHeight}}>
                                {item.image}
                            </div>
                        )
                    })}
                </div>

                <StaticImage class="pekxel" src={"../images/logo_ruleta.jpg"} alt={"Pekxel"}/>

                <div className="selector">
                    <StaticImage src={"../images/tr.png"} alt={"Selector"}/>
                </div>
            </div>

            <button style={{opacity: spinning ? '0.5' : '1'}} onClick={start}>
                {!spinning ? "Spin that wheel!" : "chanchanchan!"}
            </button>
        </div>

    </>)
}


export default Wheel
