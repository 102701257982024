import * as React from "react"
import Wheel from "../components/wheel"
import {StaticImage} from "gatsby-plugin-image";
import "./index.scss"
import {useLayoutEffect, useRef, useState} from "react";

import gif1 from "../images/gifs/thanos-baile.gif"
import gif2 from "../images/gifs/internetrapido.gif"
import gif3 from "../images/gifs/clientes.gif"
import gif4 from "../images/gifs/dinero.gif"
import gif5 from "../images/gifs/alegrias.gif"
import gif6 from "../images/gifs/amor.gif"
import gif7 from "../images/gifs/comersinengordar.gif"
import gif8 from "../images/gifs/sueños_metascumplidas.gif"
import gif9 from "../images/gifs/vacaciones.gif"
import gif10 from "../images/gifs/feliznavidad.gif"
import gif11 from "../images/gifs/paz.gif"
import gif12 from "../images/gifs/salud.gif"
import gif13 from "../images/gifs/felicidad.gif"

const IndexPage = () => {

    const [hasWinner, setHasWinner] = useState(false)
    const [winner, setWinner] = useState(0)
    const [sizewheel, setSizewheel] = useState(500)
    const mainRef = useRef(null);

    useLayoutEffect(() => {
        if (mainRef.current) {
            setSizewheel(mainRef.current.offsetWidth / 2.9)
        }
    }, []);

    let result = r => {
        setWinner(r - 1)
        setHasWinner(true)
    }


    const winners = [
        {
            frase: "Lalo te desea diversión sin fin.",
            imagen: <img src={gif1} alt={"Gift"}/>
        },
        {
            frase: "Daniela te desea una feliz navidad.",
            imagen: <img src={gif10} alt={"Gift"}/>
        },
        {
            frase: "Pekxel te desea muchos clientes más.",
            imagen: <img src={gif3} alt={"Gift"}/>
        },
        {
            frase: "Abner te desea dinero, dinero, dinero ...",
            imagen: <img src={gif4} alt={"Gift"}/>
        },
        {
            frase: "Raúl te desea muchas alegrías",
            imagen: <img src={gif5} alt={"Gift"}/>
        },
        {
            frase: "Iván te desea un año de amor.",
            imagen: <img src={gif6} alt={"Gift"}/>
        },
        {
            frase: "Jorge te desea poder comer sin engordar.",
            imagen: <img src={gif7} alt={"Gift"}/>
        },
        {
            frase: "Armin te desea un año de sueños y metas cumplidas.",
            imagen: <img src={gif8} alt={"Gift"}/>
        },
        {
            frase: "Ariux te desea las vacaciones tu vida.",
            imagen: <img src={gif9} alt={"Gift"}/>
        },
        {
            frase: "Ernesto te desea un internet rápido.",
            imagen: <img src={gif2} alt={"Gift"}/>
        },
        {
            frase: "Diego te desea paz y tranquilidad",
            imagen: <img src={gif11} alt={"Gift"}/>
        },
        {
            frase: "Matzerath te desea salud y propseridad.",
            imagen: <img src={gif12} alt={"Gift"}/>
        },
        {
            frase: "Pekxel te desea un burrito sabanero",
            imagen: <img src={gif13} alt={"Gift"}/>
        },

    ]

    return (
        <main>
            <title>Home Page</title>
            <a className={'pekxellogo'} href="https://pekxel.com">
                <img src="https://pekxel.com/img/icons/pink-logo.png" alt="Pekxel"/>
            </a>
            <div className="contenedor" ref={mainRef}>
                <div className="fondo">
                    <StaticImage className={"franja-nieve"} src={"../images/fondo.png"} alt={"Selector"}/>
                    <StaticImage className={"santa"} src={"../images/santa.png"} alt={"Selector"}/>

                    <StaticImage className="snowflake snow1" src={"../images/snowflake.svg"} alt={"xmas pekxel"}/>
                    <StaticImage className="snowflake snow2" src={"../images/snowflake.svg"} alt={"xmas pekxel"}/>
                    <StaticImage className="snowflake snow3" src={"../images/snowflake.svg"} alt={"xmas pekxel"}/>
                    <StaticImage className="snowflake snow4" src={"../images/snowflake.svg"} alt={"xmas pekxel"}/>
                    <StaticImage className="snowflake snow5" src={"../images/snowflake.svg"} alt={"xmas pekxel"}/>
                    <StaticImage className="dot" src={"../images/dot.svg"} alt={"xmas pekxel"}/>
                    <div className="pink-row">
                        <StaticImage className="pink-circle pink1" src={"../images/circle.png"} alt={"xmas pekxel"}/>
                        <StaticImage className="pink-circle pink2" src={"../images/circle.png"} alt={"xmas pekxel"}/>
                        <StaticImage className="pink-circle pink3" src={"../images/circle.png"} alt={"xmas pekxel"}/>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="col1 left">
                        <h1>¡Descubre tu <span>deseo
                            <svg width="162" height="58" viewBox="0 0 162 58" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.51884 19.8385V20.6651C7.76712 19.1496 9.15411 17.9097 10.5411 17.2209C11.9281 16.5321 13.4538 16.1188 15.2569 16.1188C17.3373 16.1188 19.2791 16.6698 21.0822 17.772C22.8853 18.8741 24.2723 20.3896 25.2432 22.4561C26.214 24.5226 26.7688 27.0024 26.7688 29.8955C26.7688 31.962 26.4914 33.8907 25.9366 35.6817C25.3819 37.4727 24.5497 38.8504 23.4401 40.0903C22.4692 41.3302 21.2209 42.1568 19.6952 42.8456C18.3082 43.5344 16.7825 43.81 15.1182 43.81C13.1764 43.81 11.512 43.3967 10.125 42.5701C8.73801 41.7435 7.48973 40.6413 6.38014 39.1259V49.0451C6.38014 52.076 5.27055 53.5915 3.05137 53.5915C1.80308 53.5915 0.97089 53.1782 0.554795 52.4893C0.138699 51.5249 0 50.4228 0 48.9074V19.8385C0 18.5986 0.277397 17.6342 0.832192 16.9454C1.38699 16.2565 2.21918 15.981 3.19007 15.981C4.16096 15.981 4.99315 16.2565 5.54795 16.9454C6.10274 17.6342 6.51884 18.5986 6.51884 19.8385ZM19.8339 29.7577C19.8339 27.9668 19.5565 26.4513 19.0017 25.0736C18.4469 23.8337 17.7534 22.7316 16.6438 22.0428C15.6729 21.3539 14.5634 20.9406 13.4538 20.9406C11.512 20.9406 9.9863 21.6295 8.73801 23.1449C7.48973 24.6603 6.79623 26.8646 6.79623 29.62C6.79623 32.3753 7.48973 34.4418 8.73801 35.9573C9.9863 37.4727 11.6507 38.1615 13.5925 38.1615C14.7021 38.1615 15.8116 37.886 16.7825 37.1972C17.7534 36.5083 18.5856 35.5439 19.1404 34.304C19.5565 33.2019 19.8339 31.5487 19.8339 29.7577Z"
                                    fill="black"/>
                                <path
                                    d="M50.3476 31.5487H36.8938C36.8938 33.0641 37.1712 34.4418 37.8647 35.6817C38.4195 36.9216 39.2517 37.7482 40.3613 38.2993C41.3321 38.8504 42.4417 39.2637 43.69 39.2637C44.5222 39.2637 45.2157 39.1259 45.9092 38.9881C46.6027 38.8504 47.2962 38.4371 47.851 38.1615C48.5445 37.7482 49.0993 37.3349 49.6541 36.7839C50.2089 36.3706 50.9024 35.6817 51.7345 34.8551C52.1506 34.5796 52.5667 34.4418 53.2602 34.4418C53.9537 34.4418 54.5085 34.5796 54.9246 34.9929C55.3407 35.4062 55.6181 35.9572 55.6181 36.6461C55.6181 37.1972 55.3407 38.0238 54.9246 38.8504C54.5085 39.677 53.6763 40.5036 52.7054 41.1924C51.7345 42.019 50.4863 42.5701 48.9606 43.1211C47.4349 43.6722 45.7705 43.81 43.8287 43.81C39.3904 43.81 36.0616 42.5701 33.565 40.0903C31.0685 37.6105 29.9589 34.1663 29.9589 29.8955C29.9589 27.829 30.2363 26.038 30.9298 24.247C31.4845 22.5938 32.4554 21.0784 33.565 19.8385C34.6746 18.5986 36.2003 17.6342 37.8647 16.9454C39.5291 16.2565 41.4708 15.981 43.5513 15.981C46.1866 15.981 48.5445 16.5321 50.4863 17.6342C52.428 18.7363 53.815 20.2518 54.7859 22.0428C55.7568 23.8337 56.1729 25.6247 56.1729 27.4157C56.1729 29.0689 55.6181 30.171 54.6472 30.7221C53.5376 31.2732 52.1506 31.5487 50.3476 31.5487ZM37.0325 27.6912H49.5154C49.3767 25.3492 48.6832 23.5582 47.5736 22.4561C46.464 21.3539 45.077 20.6651 43.2739 20.6651C41.6095 20.6651 40.2226 21.2162 39.113 22.4561C37.8647 23.696 37.1712 25.3492 37.0325 27.6912Z"
                                    fill="black"/>
                                <path
                                    d="M67.2688 39.677C67.2688 41.0546 66.8527 42.019 66.1592 42.7078C65.4657 43.3967 64.6335 43.81 63.8013 43.81C62.6917 43.81 61.8595 43.3967 61.3048 42.7078C60.75 42.019 60.3339 40.9169 60.3339 39.677V14.8789C60.3339 13.3634 60.6113 12.2613 61.1661 11.4347C61.7208 10.6081 62.553 10.1948 63.6626 10.1948C64.7722 10.1948 65.6044 10.6081 66.1592 11.2969C66.714 11.9858 67.1301 13.0879 67.1301 14.4656V39.677H67.2688Z"
                                    fill="black"/>
                                <path
                                    d="M103.608 40.9169L98.6147 33.6152L93.4828 40.9169C92.7893 41.8812 92.0958 42.7078 91.6797 43.1211C91.1249 43.5344 90.5701 43.81 89.738 43.81C88.9058 43.81 88.0736 43.5344 87.5188 42.9834C86.964 42.4323 86.5479 41.8812 86.5479 41.1924C86.5479 40.5036 86.964 39.4014 87.9349 38.1615L94.1763 29.3444L76.9777 5.92399C76.0068 4.68409 75.5907 3.71971 75.5907 2.75534C75.5907 2.06651 75.8681 1.51544 76.4229 0.964371C76.9777 0.275534 77.8099 0 78.6421 0C79.4743 0 80.1678 0.275534 80.5838 0.688836C81.1386 1.10214 81.8321 1.79097 82.5256 2.75534L98.6147 24.7981L102.914 18.7363C103.608 17.772 104.301 17.0831 104.717 16.5321C105.272 16.1188 105.966 15.8432 106.659 15.8432C107.491 15.8432 108.324 16.1188 108.878 16.6698C109.433 17.2209 109.711 17.772 109.711 18.4608C109.711 19.0119 109.572 19.4252 109.294 19.9762C109.017 20.3895 108.74 21.0784 108.185 21.6295L102.637 28.9311L108.878 37.7482C109.849 39.1259 110.265 40.228 110.265 40.9169C110.265 41.6057 109.988 42.2945 109.294 42.7078C108.74 43.2589 107.907 43.5344 107.075 43.5344C106.243 43.5344 105.688 43.2589 105.134 42.8456C104.856 42.5701 104.301 41.8812 103.608 40.9169Z"
                                    fill="black"/>
                                <path
                                    d="M66.9915 28.9311L74.2038 18.7363C74.8973 17.772 75.5908 17.0831 76.0069 16.5321C76.5617 16.1188 77.2552 15.8432 77.9486 15.8432C78.7808 15.8432 79.613 16.1188 80.1678 16.6698C80.7226 17.2209 81 17.772 81 18.4608C81 19.0119 80.8613 19.4252 80.5839 19.9762C80.3065 20.3895 80.0291 21.0784 79.4743 21.6295L73.9264 28.9311L90.0154 51.9382C90.9863 53.3159 91.4024 54.4181 91.4024 55.1069C91.4024 55.7957 91.125 56.4846 90.4315 56.8979C89.8767 57.4489 89.0445 57.7245 88.2123 57.7245C87.3801 57.7245 86.8254 57.4489 86.2706 57.0356C85.7158 56.6223 85.161 55.9335 84.3288 54.8314L66.9915 29.3444V28.9311Z"
                                    fill="black"/>
                                <path
                                    d="M132.735 31.5487H119.281C119.281 33.0641 119.558 34.4418 120.252 35.6817C120.807 36.9216 121.639 37.7482 122.748 38.2993C123.719 38.8504 124.829 39.2637 126.077 39.2637C126.909 39.2637 127.603 39.1259 128.296 38.9881C128.99 38.8504 129.683 38.4371 130.238 38.1615C130.932 37.7482 131.486 37.3349 132.041 36.7839C132.596 36.3706 133.289 35.6817 134.122 34.8551C134.538 34.5796 134.954 34.4418 135.647 34.4418C136.341 34.4418 136.896 34.5796 137.312 34.9929C137.728 35.4062 138.005 35.9572 138.005 36.6461C138.005 37.1972 137.728 38.0238 137.312 38.8504C136.896 39.677 136.063 40.5036 135.093 41.1924C134.122 42.019 132.873 42.5701 131.348 43.1211C129.822 43.6722 128.158 43.81 126.216 43.81C121.777 43.81 118.449 42.5701 115.952 40.0903C113.456 37.6105 112.346 34.1663 112.346 29.8955C112.346 27.829 112.623 26.038 113.317 24.247C113.872 22.5938 114.843 21.0784 115.952 19.8385C117.062 18.5986 118.587 17.6342 120.252 16.9454C121.916 16.2565 123.858 15.981 125.938 15.981C128.574 15.981 130.932 16.5321 132.873 17.6342C134.815 18.7363 136.202 20.2518 137.173 22.0428C138.144 23.8337 138.56 25.6247 138.56 27.4157C138.56 29.0689 138.005 30.171 137.034 30.7221C135.925 31.2732 134.538 31.5487 132.735 31.5487ZM119.42 27.6912H131.902C131.764 25.3492 131.07 23.5582 129.961 22.4561C128.851 21.3539 127.464 20.6651 125.661 20.6651C123.997 20.6651 122.61 21.2162 121.5 22.4561C120.252 23.696 119.558 25.3492 119.42 27.6912Z"
                                    fill="black"/>
                                <path
                                    d="M161.168 38.9881C160.613 38.4371 159.781 38.1615 158.671 38.1615H150.211V20.3896C150.211 19.0119 149.933 17.9097 149.24 17.2209C148.546 16.5321 147.714 16.1188 146.743 16.1188C145.772 16.1188 144.94 16.5321 144.247 17.2209C143.692 17.9097 143.276 19.0119 143.276 20.2518V39.4014C143.276 40.7791 143.553 41.7435 144.247 42.4323C144.94 43.1211 145.634 43.5345 146.743 43.5345H146.882H158.671C159.781 43.5345 160.613 43.2589 161.168 42.7078C161.723 42.1568 162 41.6057 162 40.7791C162 40.0903 161.723 39.4014 161.168 38.9881Z"
                                    fill="black"/>
                            </svg>
                            !</span></h1>
                        <p>
                            Cada integrante del equipo tiene un deseo diferente para ti,
                            descúbrelo haciendo girar la ruleta.
                        </p>
                    </div>
                    <div className="col2">
                        <Wheel size={sizewheel} cb={result}/>
                    </div>
                    <div className="col1"></div>
                </div>
            </div>

            <div className={`popup ${hasWinner ? 'active' : ''}`}>
                <div className="container">
                    <div className="info">
                        {winners[winner].imagen}
                        <div className="gift">
                            <button onClick={() => setHasWinner(false)} className={"close"}>X</button>
                            {winners[winner].frase}
                            <button onClick={() => setHasWinner(false)}>Awww</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}


export default IndexPage;


